/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-28",
    versionChannel: "nightly",
    buildDate: "2023-03-28T00:23:30.870Z",
    commitHash: "fc4b51b20f3b59aa38d65f4be15fedd93242249f",
};
